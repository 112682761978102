import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Grupo } from '../../models/grupo';
import { MensajeLightSave } from '../../models/mensaje-light-save';
import { MensajesService } from '../../services';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from "@ckeditor/ckeditor5-angular/ckeditor.component";
import { ListaUsuariosComponent } from '../../components/lista-usuarios/lista-usuarios.component';
import { CommonService } from '@app/modules/shared/services/common.service';
import { DestinatarioNotificado } from '../../models/destinatario-notificado';
import { Destinatario } from '../../models/destinatario';
import { FiltrosEnviarMensajeComponent } from '../../components/filtros-enviar-mensaje/filtros-enviar-mensaje.component';
import { AuthenticationService } from '../../../security/authentication.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-mensaje-editar',
  templateUrl: './mensaje-editar.component.html',
  styleUrls: ['./mensaje-editar.component.css']
})
export class MensajeEditarComponent implements OnInit{
  @ViewChild('ckeditor') CKEDITOR:ElementRef;
  @ViewChild(ListaUsuariosComponent) childListaUsuario: ListaUsuariosComponent;
  @ViewChild(ListaUsuariosComponent) childFiltros: FiltrosEnviarMensajeComponent;
  public Editor = ClassicEditor;
  showNotificados: boolean = false;
  formGroup: FormGroup;
  usuarios: Destinatario[] = [];
  filteredGrupos: Observable<Grupo[]>;
  filteredSubGrupo: Observable<Grupo[]>;
  filteredSubGrupo1: Observable<Grupo[]>;
  notificados: DestinatarioNotificado[] = [];
  isLoading: boolean = false;
  mail: number = 0;
  editorData: string = null;
  private entity: MensajeLightSave;
  imagePath : string = "";
  isFileAttaching: boolean = false;
  config = { toolbar: ["undo", "redo", "bold", "italic", "blockQuote", "heading", "link", "numberedList", "bulletedList", "insertTable", "tableColumn", "tableRow", "mergeTableCells"] }

  constructor(private router:Router, private formBuilder: FormBuilder, private domSanitizer: DomSanitizer,
    private route: ActivatedRoute,private commonService: CommonService, private service: MensajesService, private authenticationService: AuthenticationService ) {
        this.commonService.setTitulo('Edicion de Mensajes');
    }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
        'usuarios': [null, Validators.required],
        'contenido': [null],
        'contenidoRtf': [null, Validators.required],
        'imagen': [null],
        'mail': [null]
    });
    this.formGroup.valueChanges.subscribe(values => {
      console.log(values);
    })
    this.formGroup.controls.mail.setValue(0);
    this.route.params.subscribe(params => {
      if(params['id']!='0'){
        this.isLoading = true;
        this.service.GetMensajeLight(Number(params['id'])).subscribe(data=>{
          this.entity = data;
          this.mail = this.entity.flgNotificacionEmail;
          this.formGroup.patchValue(this.entity);
          this.imagePath = data.imagen;
          this.isLoading = false;
        },
        (err: HttpErrorResponse) => {this.isLoading = false;});
        this.isLoading = true;
        this.service.GetDestinatariosNotificados(Number(params['id'])).subscribe(data=>{
          this.showNotificados = true;
          this.notificados = data;
          console.log("Notificados:", this.notificados)
          this.isLoading = false;
        },
        (err: HttpErrorResponse) => {
          this.isLoading = false;
        });
      }else{
        this.entity = {id: 0, contenido: "", contenidoRtf: "", imagen: "", usuarios: [], mail: 0, flgNotificacionEmail: 0, flgNotificacionWhatsApp: 0};
        this.mail = 0;
      }
    });

  }

  onNewFileAttach(event: any): void{
    console.log(event);
    if (event?.target?.files != null && event.target.files.length > 0) {
      var fileList : File = event.target.files;
      if (fileList != null) {
        this.isFileAttaching = true;
        this.service.SetImagenMensaje(fileList[0]).subscribe(x => {
          this.isFileAttaching = false;
          this.formGroup.controls.imagen.setValue(x.url);
          this.imagePath = x.fileBase;
        }, () => {
          this.isFileAttaching = false;
          //this.toastrService.error("No se pudieron adjuntar los archivos.", "Error");
        });
      }
    }
    event.target.value = null;
  }

  onDeleteImage(){
    var imagePath = this.formGroup.controls.imagen.value;
    if(imagePath != ""){
      this.isFileAttaching = true;
      this.service.DeleteImagenMensaje(imagePath).subscribe(data =>{
        console.log(data);
        this.isFileAttaching = false;
        this.formGroup.controls.imagen.setValue("");
        this.imagePath = "";
      }, () => {
        this.isFileAttaching = false;
        //this.toastrService.error("No se pudieron adjuntar los archivos.", "Error");
      });
    }
  }

  onCancelar(){
    this.router.navigate(['mensajeria/lista']);
  }

  onGuardar() {
    let convert = this.formGroup.controls.contenidoRtf.value;
    let text = convert.replace(/<[^>]+>/g, '');
    //let text1 = this.CKEDITOR.nativeElement.getBody().getText()
    let value = {...this.entity,... this.formGroup.getRawValue(),...{contenido: text}};
    console.log(value)
    this.isLoading = true;
    this.service.SetMensajeLight(value).subscribe(data=>{
      this.isLoading = false;
      this.router.navigate(['mensajeria/lista']);
    },
    (err: HttpErrorResponse) => { this.isLoading = false; });
  }

  onAddedFiltros(item: Destinatario[]){
    this.usuarios = [];
    this.usuarios = item;
  }

  onAddedUsuarios(item: Destinatario[]){
    this.formGroup.controls.usuarios.setValue(item);
  }

  onAddedMail(item: number){
    this.formGroup.controls.mail.setValue(item);
  }

  onAddedLoading(item: boolean){
    this.isLoading = item;
  }
}
