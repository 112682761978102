import { Injectable } from '@angular/core';
import { AppConfig } from '../../../configs/app.config';

import { BehaviorSubject, Observable, of, throwError as observableThrowError } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CommonService {
  public titulo: Observable<string>;
  private tituloSubject: BehaviorSubject<string>;

  constructor(public snackBar: MatSnackBar, private httpClient: HttpClient) {
    // Mantiene el ultimo titulo usado.
    this.tituloSubject = new BehaviorSubject<string>(localStorage.getItem('currentTitle'));
    this.titulo = this.tituloSubject.asObservable();
  }

  public setTitulo(newTitle) {
    localStorage.setItem('currentTitle', newTitle);
    this.tituloSubject.next(newTitle);
  }

  public showSnackBar(name): void {
    const config: any = new MatSnackBarConfig();
    config.duration = AppConfig.settings.snackBarDuration;
    config.verticalPosition = 'top';
    this.snackBar.open(name, 'OK', config);
  }

  public showSnackBarFatal(name): void {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.duration = AppConfig.settings.snackBarDuration;
    config.verticalPosition = 'top';
    config.panelClass = ['warn-bg'];
    this.snackBar.open(name, '', config);
  }

  public showSnackBarSucces(name): void {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.duration = AppConfig.settings.snackBarDuration;
    config.verticalPosition = 'top';
    config.panelClass = ['style-succes'];
    this.snackBar.open(name, 'OK', config);
  }

  public createGetRequets(data: string[]) {
    const getRequests = [];
    data.forEach(url =>
      getRequests.push(
        this.httpClient.get(url, { responseType: 'blob' })
      )
    );
    return getRequests;
  }

  public createOneGetRequets(url: string): Observable<Blob> {
     return this.httpClient.get(url, { responseType: 'blob' });
  }

  public getFileName(url: string) {
    let fileName: String;
    if (url.includes('\\')) {
      fileName = url.substring(url.lastIndexOf('\\') + 1);
    } else {
      fileName = url.substring(url.lastIndexOf('/') + 1);
    }
    return fileName;
  }
}
