<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" style="margin-bottom:  10px; margin-top: 10px;">
  <div fxFlex="40%" fxFlex.xs="100%">
    <div class="mat-elevation-z8">
      <select style="width: 100%;" multiple size="20">
        <option *ngFor="let item of usuariosAgregar" style="margin-top: 5px; width: 100%;" (click)="select(item.id)">{{item.nombre}}</option>
      </select>
    </div>
  </div> 
  <div fxFlex="20%" fxFlex.xs="100%">
    <div style=" margin-top: 50%">
      <div class="row">
        <button mat-raised-button type="submit" style="width: 100%;" (click)="agregar()">Agregar</button>
      </div>
      <div class="row">
        <button mat-raised-button type="submit" style="margin-top: 5px; width: 100%;" (click)="agregarTodos()">Agregar Todos</button>
      </div>
      <div class="row">
        <button mat-raised-button type="submit" style="margin-top: 5px; width: 100%;" color="warn" (click)="borrar()">Borrar</button>
      </div>
      <div class="row">
        <button mat-raised-button type="submit" style="margin-top: 5px; margin-bottom: 15px; width: 100%;" color="warn" (click)="borrarTodos()">Borrar Todos</button>
      </div>
    </div>      
  </div> 
  <div style="margin-left: 20%;" fxFlex="40%" fxFlex.xs="100%">
      <div class="mat-elevation-z8">
        <select style="width: 100%;"  multiple size="20">
          <option *ngFor="let item of usuariosEnviar" style="margin-top: 5px; width: 100%;" (click)="selectBorrar(item.id)">{{item.nombre}}</option>
        </select>
      </div>
  </div>                          
</div>
