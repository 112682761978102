<!--   -->
<div class="padding-container">
    <div class="mat-elevation-z6 table-custom-margin">
        <div class="padding-container">
            <form [formGroup]="formGroup">
                <mat-tab-group style="width: 100%;">
                    <mat-tab label="Usuarios">
                        <app-filtros-enviar-mensaje [mail]="mail" (destinatarioEmitt)="onAddedFiltros($event)" (mailEmitt)="onAddedMail($event)" (loadingEmitt)="onAddedLoading($event)"></app-filtros-enviar-mensaje>
                        <app-lista-usuarios (usuariosEmitted)="onAddedUsuarios($event)" [usuariosRecive]="usuarios"></app-lista-usuarios>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" style="margin-top: 10px;">
                            <div fxFlex="100%" fxFlex.xs="100%">
                                <button mat-raised-button type="submit" color="warn" style="margin-left: 10px;" (click)="onCancelar()">Cancelar</button>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Contenido">
                        <div style="margin-top: 10px;" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                            <div fxFlex="100%" fxFlex.xs="100%">
                                <ckeditor style="min-height: 500px;" height="500px" [editor]="Editor" [config]="config" formControlName="contenidoRtf"></ckeditor>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="50px" style="margin-top: 10px;">
                            <button mat-icon-button matSuffix (click)="fileInput.click()" [disabled]="isFileAttaching"><mat-icon>attach_file</mat-icon>Agregar imagen</button>
                        </div>
                        <div fxLayout="row" fxLayoutGap="50px" style="margin-top: 10px; margin-left: 10px;">
                            <input hidden type="file" accept="image/*" (change)="onNewFileAttach($event)" #fileInput multiple>
                            <mat-icon *ngIf="imagePath != '' && imagePath != null " style="cursor: pointer;" (click)="onDeleteImage()">close</mat-icon><img *ngIf="imagePath != '' && imagePath != null " [src]="imagePath" width="30" heigth="30">
                            <mat-label *ngIf="isFileAttaching"><br/><i class="fa fa-spinner fa-spin"></i> Subiendo archivo...</mat-label>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" style="margin-top: 10px;">
                            <div fxFlex="100%" fxFlex.xs="100%">
                                <button mat-raised-button type="submit" [disabled]="!formGroup.valid" (click)="onGuardar()">Enviar</button>
                                <button mat-raised-button type="submit" color="warn" style="margin-left: 10px;" (click)="onCancelar()">Cancelar</button>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Notificados" *ngIf="showNotificados">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                            <div fxFlex="100%" fxFlex.xs="100%">
                                <table id="table" style="width: 40%; margin-top: 10px;">
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Mail</th>
                                        <th>Aplicacion</th>
                                    </tr>
                                    <tr *ngFor="let item of notificados">
                                        <td>{{item?.nombre}}</td>
                                        <td style="text-align: center;"><mat-icon *ngIf="item.mailOpen == 1">send</mat-icon><mat-icon *ngIf="item.mailOpen == 2">email</mat-icon><mat-icon *ngIf="item.mailOpen == 3">error</mat-icon></td>
                                        <td style="text-align: center;"><mat-icon *ngIf="item.appOpen >= 1">visibility</mat-icon><mat-icon *ngIf="item.appOpen == 0">visibility_off</mat-icon></td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                    </mat-tab>

                </mat-tab-group>
            </form>
        </div>

    </div>

</div>
