<div class="padding-container">
    <mat-form-field class="ml-5 mr-5">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar..." type="text" [formControl]="descripcionInput" name="Descripcion">
    </mat-form-field>
    <button mat-raised-button type="submit" (click)="getMensajes()" [disabled]="isLoading">Buscar</button>
    <button mat-raised-button type="submit" style="margin-left: 10px;" (click)="edicion(0)">Nuevo</button>
    <div class="mat-elevation-z8">
        <mat-table [dataSource]="mtMen" matSort>
            <ng-container matColumnDef="fecha">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="mobile-label">Fecha:</span> {{element.fecha | date: 'dd/MM/yyyy'}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="emisor">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Emisor</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="mobile-label">Emisor:</span> {{element.emisor}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="contenido">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Contenido</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="mobile-label">Contenido:</span> {{element.contenido}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="notificados">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Notificados</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="mobile-label">Notificados:</span> {{element.notificados}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="leidos">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Leidos</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="mobile-label">Leidos:</span> {{element.leidos}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="editar">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Modificar</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="edicion(element.id)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="dcMen"></mat-header-row>
            <mat-row *matRowDef="let row; columns: dcMen; "></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[7, 10, 25, 100]" [hidden]="mtMen.data.length == 0 || isLoading"></mat-paginator>

        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; height: 200px; background-color:white">
            <mat-progress-spinner color="primary" mode="indeterminate" [diameter]="50">
            </mat-progress-spinner>
        </div>

        <div style="text-align: center; padding-top: 40px; padding-bottom: 40px;" *ngIf="!isLoading && mtMen.data.length === 0">
            NO SE ENCONTRARON RESULTADOS PARA LA BUSQUEDA
        </div>
    </div>
</div>