/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from '@app/modules/core/pages/not-found/not-found.component';
import { AuthGuard } from '@app/modules/security/helpers';
import { SecureLayoutComponent } from '@app/modules/core/layouts/secure-layout/secure-layout.component';
import { PublicLayoutComponent } from '@app/modules/core/layouts/public-layout/public-layout.component';

const routes: Routes = [
  { path: '', redirectTo: '/mensajeria/mensajes', pathMatch: 'full' },
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./modules/security/security.module').then(m => m.SecurityModule)
      }
    ]
  },
  {
    path: '',
    component: SecureLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reportes',
        loadChildren: () => import('./modules/bitacoras/bitacora.module').then(m => m.BitacoraModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'bitacoras',
        loadChildren: () => import('./modules/bitacoras/bitacora.module').then(m => m.BitacoraModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contact-center',
        loadChildren: () => import('./modules/contact-center/contactCenter.module').then(m => m.ContactCenterModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'cuentas-corrientes',
        loadChildren: () => import('./modules/cuentas-corrientes/cuentas-corrientes.module').then(m => m.CuentasCorrientesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'afiliaciones',
        loadChildren: () => import('./modules/afiliaciones/afiliaciones.module').then(m => m.AfiliacionesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'actividadesclientes',
        loadChildren: () => import('./modules/actividades-clientes/actividades-clientes.module').then(m => m.ActividadesClientesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'electros',
        loadChildren: () => import('./modules/electros/electros.module').then(m => m.ElectrosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'pamitraslados',
        loadChildren: () => import('./modules/pami-traslados/pami-traslados.module').then(m => m.PamiTrasladosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'extranet-v1',
        loadChildren: () => import('./modules/extranet-v1/extranet-v1.module').then(m => m.ExtranetV1Module),
      },
      {
        path: 'moli',
      loadChildren: () => import('./modules/moli/moli.module').then(m => m.MoliModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'facturacion',
      loadChildren: () => import('./modules/facturacion/facturacion.module').then(m => m.FacturacionModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'mensajeria',
        loadChildren: () => import('./modules/mensajeria/mensajeria.module').then(m => m.MensajeriaModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'configuracion',
        loadChildren: () => import('./modules/configuracion/configuracion.module').then(m => m.ConfiguracionModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'preincidentes',
        loadChildren: () => import('./modules/preincidentes-clientes/preincidentes-clientes.module')
        .then(m => m.PreIncidentesClientesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'liquidaciones',
        loadChildren: () => import('./modules/liquidaciones/liquidaciones.module').then(m => m.LiquidacionesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'tercerizacion-servicios',
        loadChildren: () => import('./modules/tercerizacion-servicios/tercerizacion-servicios.module')
        .then(m => m.TercerizacionServiciosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'recetas',
        loadChildren: () => import('./modules/recetas/recetas.module').then(m => m.RecetaModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'hisopados',
        loadChildren: () => import('./modules/hisopados/hisopados.module').then(m => m.HisopadosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'pacientes-covid',
        loadChildren: () => import('./modules/pacientes-covid/pacientes-covid.module').then(m => m.PacientesCovidModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'hisopados-pacientes',
        loadChildren: () => import('./modules/hisopados-pacientes/hisopados-pacientes.module').then(m => m.HisopadosPacientesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'evaluaciones-desempenio',
        loadChildren: () => import('./modules/evaluaciones-desempenio/evaluaciones-desempenio.module')
        .then(m => m.EvaluacionesDempenioModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'auditorias-moviles',
        loadChildren: () => import('./modules/auditorias-moviles/auditorias-moviles.module').then(m => m.AuditoriasMovilesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'auditorias-bases',
        loadChildren: () => import('./modules/auditorias-bases/auditorias-bases.module').then(m => m.AuditoriasBasesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'auditorias',
        loadChildren: () => import('./modules/auditorias-master/auditorias-master.module').then(m => m.AuditoriasMasterModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'mantenimiento-bases',
        loadChildren: () => import('./modules/mantenimiento-bases/mantenimiento-bases.module').then(m => m.MantenimientoBasesModule),
        canActivate: [AuthGuard]
      },
    ],
  },
  {
    path: 'micrositioQr',
    loadChildren: () => import('./modules/micrositio-qr/micrositio-qr.module').then(m => m.MicrositioQrModule),
  },
  {
    path: 'micrositioPdf',
    loadChildren: () => import('./modules/micrositio-pdf/micrositio-pdf.module').then(m => m.MicrositioPdfModule),
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    // Averiguar
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy',
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
