import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppConfig } from '@app/configs/app.config';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUser;
        if (currentUser) {
            // logged in so return true
            return true;
        }
        console.log(state);
        if(state.url.includes('micrositioQr') || state.url.includes('micrositioPdf')) {
          return this.login();
        }
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
    async login() {
      let retornado= await this.authenticationService.login(AppConfig.settings.micrositioQr.usuario,
         AppConfig.settings.micrositioQr.contrasena )
      .pipe(first()).toPromise();
      console.log(retornado);
      if(retornado!=null){
        return true;
      }else{
        return false;
      }
    }
}
