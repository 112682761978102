<h1 mat-dialog-title>Recuperar contraseña</h1>
<div mat-dialog-content>
    <mat-form-field class="full-width">
        <input matInput type="email" placeholder="Email" [formControl]="emailFormControl" required>
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          Por favor ingrese un email valido.
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          Email es <strong>requerido</strong>
        </mat-error>
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="accent" (click)="onCancelClick()">Cancelar</button>
  <button mat-raised-button color="accent" (click)="onSendClick()" [disabled]="!emailFormControl.valid">Enviar</button>
</div>
