import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { AppConfig } from '@app/configs/app.config';
import { MensajeItem } from '../models/mensaje-item.model';
import { Mensaje } from '../models/mensaje.model';
import { MensajeLight } from '../models/mensaje-light';
import { MensajeLightSave } from '../models/mensaje-light-save';
import { Grupo } from '../models/grupo';
import { Destino } from '@app/models/destino.model';
import { Destinatario } from '../models/destinatario';
import { DestinatarioNotificado } from '../models/destinatario-notificado';

@Injectable({
  providedIn: 'root'
})

export class MensajesService {

  mensajesApiUrl: string;
  private cantMensajesPendientes = new BehaviorSubject<number>(0);
  private cantMensajes = new BehaviorSubject<number>(0);

  constructor(private httpClient: HttpClient) {
    this.mensajesApiUrl = AppConfig.settings.endpoints.api + 'Mensajes';
    console.log(AppConfig.settings);
  }

  get currentCantMensjesPendientes() {
    return this.cantMensajesPendientes.asObservable();
  }

  get currentCantMensjes() {
    return this.cantMensajes.asObservable();
  }

  public GetMensajesItems(): Observable<MensajeItem[]> {
    const url = this.mensajesApiUrl;
    return this.httpClient.get<MensajeItem[]>(url).pipe(
      map((mensajesItem: MensajeItem[]) => {
        let canMensajes = mensajesItem == null ? 0 : mensajesItem.length;
        this.cantMensajes.next(canMensajes);
        return mensajesItem;
      })
    );
  }

  public GetMensaje(id: number): Observable<Mensaje> {
    const url = `${this.mensajesApiUrl}/${id}`;
    return this.httpClient.get<Mensaje>(url).pipe(shareReplay());
  }

  public RefreshCantidadMensajesPendientes(): void {
    const url = `${this.mensajesApiUrl}/CantidadPendientes`;
    this.httpClient.get<number>(url).pipe(
      map((cantidad: number) => {
        this.cantMensajesPendientes.next(cantidad);
        return cantidad;
      })
    ).subscribe();
  }

  public SetLeido(id: number): Observable<boolean> {
    const url = `${this.mensajesApiUrl}/${id}/Leido`;
    return this.httpClient.post<boolean>(url, null).pipe(
      map((leiod: boolean) => {
        this.cantMensajesPendientes.next(this.cantMensajesPendientes.value - 1);
        return leiod;
      })
    );
  }

  public GetMensajesLight(): Observable<MensajeLight[]> {
    const url = `${this.mensajesApiUrl}/MensajesLight`;
    return this.httpClient.get<MensajeLight[]>(url);
  }

  public GetMensajeLight(id: number): Observable<MensajeLightSave> {
    const url = `${this.mensajesApiUrl}/MensajeLight/${id}`;
    return this.httpClient.get<MensajeLightSave>(url);
  }

  public SetMensajeLight(item): Observable<any> {
    const url = `${this.mensajesApiUrl}/SetMensajesLight`;
    return this.httpClient.post<any>(url, item);
  }

  public SetImagenMensaje(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('uploadFile', file);
    const headerOptions = new HttpHeaders({ 'Accept': "multipart/form-data" });
    const url = `${this.mensajesApiUrl}/UploadImagenMensaje`;
    return this.httpClient.post<string>(url, formData, {headers: headerOptions});
  }

  public DeleteImagenMensaje(imagePath: string): Observable<any>{
    const formData = new FormData();
    formData.append('imagePath', imagePath.toString());
    const url = `${this.mensajesApiUrl}/DeleteImagenMensaje`;
    return this.httpClient.post<boolean>(url, formData);
  }

  public GetGrupos(): Observable<Grupo[]> {
    const url = `${this.mensajesApiUrl}/Grupos`;
    return this.httpClient.get<Grupo[]>(url);
  }
  public GetSubGrupos(grupoid: number): Observable<Grupo[]> {
    const url = `${this.mensajesApiUrl}/SubGrupos/${grupoid}`;
    return this.httpClient.get<Grupo[]>(url);
  }
  public GetSubGrupos1(grupoid: number, subgrupoid: number): Observable<Grupo[]> {
    const url = `${this.mensajesApiUrl}/SubGrupos1/${grupoid}/${subgrupoid}`;
    return this.httpClient.get<Grupo[]>(url);
  }
  public GetDestinatarios(grupoid: number, subgrupoid: number, subgrupo1id: number): Observable<Destinatario[]> {
    const url = `${this.mensajesApiUrl}/Destinatarios/${grupoid}/${subgrupoid}/${subgrupo1id}`;
    return this.httpClient.get<Destinatario[]>(url);
  }
  public GetDestinatariosNotificados(notiid: number): Observable<DestinatarioNotificado[]> {
    const url = `${this.mensajesApiUrl}/DestinatariosNotificados/${notiid}`;
    return this.httpClient.get<DestinatarioNotificado[]>(url);
  }
}
