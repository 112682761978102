<mat-card>
  <mat-card-header>
    <mat-card-title>{{ data.titulo }}</mat-card-title>
    <mat-card-subtitle>{{ data.subtitulo }}</mat-card-subtitle>
    <h2 *ngIf="data.error"><mat-icon>error</mat-icon></h2>
    <h2 *ngIf="!data.error"><mat-icon>danger</mat-icon></h2>
  </mat-card-header>
  <mat-card-content>
    <h3>
      <strong>{{ data.mensaje }}</strong>
    </h3>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="cerrar()">
      Aceptar
    </button>
  </mat-card-actions>
</mat-card>
