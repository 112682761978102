import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input, EventEmitter, Output, ComponentFactoryResolver } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { NotificationService } from '@app/modules/core/services/notification.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Destinatario } from '../../models/destinatario';
import { Grupo } from '../../models/grupo';
import { MensajesService } from '../../services';


@Component({
  selector: 'app-filtros-enviar-mensaje',
  templateUrl: './filtros-enviar-mensaje.component.html',
})
export class FiltrosEnviarMensajeComponent implements OnInit {
  @Input() mail: number = 0;
  @Output() destinatarioEmitt = new EventEmitter<Destinatario[]>();
  @Output() mailEmitt = new EventEmitter<number>();
  @Output() loadingEmitt = new EventEmitter<boolean>();
  formGroup: FormGroup;
  grupoList: Grupo[] = [];
  subGrupoList: Grupo[] = [];
  subGrupoBisList: Grupo[] = [];

  filteredGrupos: Observable<Grupo[]>;
  filteredSubGrupo: Observable<Grupo[]>;
  filteredSubGrupo1: Observable<Grupo[]>;

  constructor(private notService: NotificationService, private formBuilder: FormBuilder,  private service: MensajesService) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
        'grupo': [null, Validators.required],
        'grupoName': [null, Validators.required],
        'subGrupo': [null, Validators.required],
        'subGrupoName': [null, Validators.required],
        'subGrupoName1': [null],
        'subGrupo1': [null],
        'validate': ''
    });
    this.getGrupos();
    this.filteredGrupos = this.formGroup.controls.grupoName.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : (value ? '' : value?.descripcion)),
        map(nombre => nombre ? this._filterGrupo(nombre) : this.grupoList.slice())
    );

    this.filteredSubGrupo = this.formGroup.controls.subGrupoName.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : (value ? '' : value?.descripcion)),
      map(nombre => nombre ? this._filterSubGrupo(nombre) : this.subGrupoList.slice())
    );

    this.filteredSubGrupo1 = this.formGroup.controls.subGrupoName1.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : (value ? '' : value?.descripcion)),
      map(nombre => nombre ? this._filterSubGrupo1(nombre) : this.subGrupoBisList.slice())
    );
  }

  private _filterGrupo(nombre: string): Grupo[] {
    const filterValue = nombre.toLowerCase();
    return this.grupoList.filter(grupo => grupo.descripcion.toLowerCase().indexOf(filterValue) === 0);
  }
  private _filterSubGrupo(nombre: string): Grupo[] {
    const filterValue = nombre.toLowerCase();
    return this.subGrupoList.filter(grupo => grupo.descripcion.toLowerCase().indexOf(filterValue) === 0);
  }
  private _filterSubGrupo1(nombre: string): Grupo[] {
    const filterValue = nombre.toLowerCase();
    return this.subGrupoBisList.filter(grupo => grupo.descripcion.toLowerCase().indexOf(filterValue) === 0);
  }

  setGrupo(item: any){
    if(item.option.value != null){
        this.formGroup.controls.grupo.setValue(item.option.value?.id);
        this.formGroup.controls.grupoName.setValue(item.option.value?.descripcion);
    }
    else{
        this.formGroup.controls.grupo.reset();
        this.formGroup.controls.grupoName.reset();
        this.formGroup.controls.subGrupo.reset();
        this.formGroup.controls.subGrupoName.reset();
        this.formGroup.controls.subGrupo1.reset();
        this.formGroup.controls.subGrupoName1.reset();
    }
  }

  outGrupo(){
    let check = this.grupoList.find(item=> item.descripcion.toLowerCase() == this.formGroup.controls.grupoName.value?.toLowerCase());
    if(check == null){
      this.formGroup.controls.grupo.reset();
      this.formGroup.controls.grupoName.reset();
      this.formGroup.controls.subGrupo.reset();
      this.formGroup.controls.subGrupoName.reset();
      this.formGroup.controls.subGrupo1.reset();
      this.formGroup.controls.subGrupoName1.reset();
      this.subGrupoList = [];
      this.subGrupoBisList = [];
    }
    else{
      this.loadingEmitt.emit(true);
      this.service.GetSubGrupos(check.id).subscribe(data=>{
        if(data != null){
            this.subGrupoList = data;
            this.formGroup.controls.subGrupo.setValue(this.subGrupoList[0]?.id);
            this.formGroup.controls.subGrupoName.setValue(this.subGrupoList[0]?.descripcion);
            this.service.GetDestinatarios(this.formGroup.controls.grupo.value, this.subGrupoList[0]?.id, 0).subscribe(data=>{
              this.destinatarioEmitt.emit(data);
              this.loadingEmitt.emit(false);
            },
            (err: HttpErrorResponse) => {  this.loadingEmitt.emit(false); });
        }
        this.loadingEmitt.emit(false);
      },
      (err: HttpErrorResponse) => {  this.loadingEmitt.emit(false); });
    }
  }

  setSubGrupo(item: any){
    if(item.option.value != null){
      this.formGroup.controls.subGrupo.setValue(item.option.value?.id);
      this.formGroup.controls.subGrupoName.setValue(item.option.value?.descripcion);
    }
    else{
      this.formGroup.controls.subGrupo.reset();
      this.formGroup.controls.subGrupoName.reset();
      this.formGroup.controls.subGrupo1.reset();
      this.formGroup.controls.subGrupoName1.reset();
    }
  }

  outSubGrupo(){
    let check = this.subGrupoList.find(item=> item.descripcion.toLowerCase() == this.formGroup.controls.subGrupoName.value?.toLowerCase());
    if(check == null){
        this.formGroup.controls.subGrupo.reset();
        this.formGroup.controls.subGrupoName.reset();
        this.formGroup.controls.subGrupo1.reset();
        this.formGroup.controls.subGrupoName1.reset();
        this.subGrupoBisList = [];
    } else{
      this.loadingEmitt.emit(true);
      console.log(this.formGroup.controls.grupo.value, check.id)
      this.service.GetSubGrupos1(this.formGroup.controls.grupo.value, check.id).subscribe(data=>{
        console.log(data)
        if(data == null){
          this.subGrupoBisList = [];
          this.service.GetDestinatarios(this.formGroup.controls.grupo.value, check.id, 0).subscribe(data=>{
            this.destinatarioEmitt.emit(data);
            console.log("Dest sub grupo:", data)
            this.loadingEmitt.emit(false);
          },
          (err: HttpErrorResponse) => {  this.loadingEmitt.emit(false); });
        }else{
          this.subGrupoBisList = data;
          // this.formGroup.controls.subGrupo1.setValue(this.subGrupoBisList[0]?.id);
          // this.formGroup.controls.subGrupoName1.setValue(this.subGrupoBisList[0]?.descripcion);
          this.service.GetDestinatarios(this.formGroup.controls.grupo.value, check.id, 0).subscribe(data=>{
            this.destinatarioEmitt.emit(data);
            console.log("Dest sub grupo:", data)
            this.loadingEmitt.emit(false);
          },
          (err: HttpErrorResponse) => {  this.loadingEmitt.emit(false); });
        }

      },
      (err: HttpErrorResponse) => {  this.loadingEmitt.emit(false);});
    }
  }

  setSubGrupo1(item: any){
    if(item.option.value != null){
        this.formGroup.controls.subGrupo1.setValue(item.option.value?.id);
        this.formGroup.controls.subGrupoName1.setValue(item.option.value?.descripcion);
    }
    else{
        this.formGroup.controls.subGrupo1.reset();
        this.formGroup.controls.subGrupoName1.reset();
    }
  }

  outSubGrupo1(){
    let check = this.subGrupoBisList.find(item=> item.descripcion.toLowerCase() == this.formGroup.controls.subGrupoName1.value?.toLowerCase());
    if(check == null){
        this.formGroup.controls.subGrupo1.reset();
        this.formGroup.controls.subGrupoName1.reset();
    }
    else{
      this.loadingEmitt.emit(true);
      this.service.GetDestinatarios(this.formGroup.controls.grupo.value, this.formGroup.controls.subGrupo.value, check.id).subscribe(data=>{
        console.log(data);
        this.destinatarioEmitt.emit(data);
        this.loadingEmitt.emit(false);
      },
      (err: HttpErrorResponse) => { this.loadingEmitt.emit(false); });
    }
  }

  getGrupos(){
    this.loadingEmitt.emit(true);
    this.service.GetGrupos().subscribe(data=>{
      if(data != null){
        this.grupoList = data;
        this.formGroup.controls.grupo.setValue(this.grupoList[0]?.id);
        this.formGroup.controls.grupoName.setValue(this.grupoList[0]?.descripcion);
        this.service.GetSubGrupos(this.grupoList[0]?.id).subscribe(data=>{
          this.subGrupoList = data;
          this.formGroup.controls.subGrupo.setValue(this.subGrupoList[0]?.id);
          this.formGroup.controls.subGrupoName.setValue(this.subGrupoList[0]?.descripcion);
          this.service.GetDestinatarios(this.grupoList[0]?.id, this.subGrupoList[0]?.id, 0).subscribe(data=>{
            this.destinatarioEmitt.emit(data);
            this.loadingEmitt.emit(false);
          },
          (err: HttpErrorResponse) => { this.loadingEmitt.emit(false); });
          this.loadingEmitt.emit(true);
          console.log(this.grupoList[0]?.id, this.subGrupoList[0]?.id)
          this.service.GetSubGrupos1(this.grupoList[0]?.id, this.subGrupoList[0]?.id).subscribe(data=>{
            if(data == null){
              this.subGrupoBisList = [];
              this.loadingEmitt.emit(false);
            }else{
              this.subGrupoBisList = data;
              this.loadingEmitt.emit(false);
            }

          },
          (err: HttpErrorResponse) => { this.loadingEmitt.emit(false); });
        },
        (err: HttpErrorResponse) => { this.loadingEmitt.emit(false); });
      }
      this.loadingEmitt.emit(false);
    },
    (err: HttpErrorResponse) => { this.loadingEmitt.emit(false); });
  }

  cambioMail(){
    if(this.mail == 0){
      this.mail = 1;
    }
    else{
      this.mail = 0;
    }
      this.mailEmitt.emit(this.mail);
  }
}
