<h1 mat-dialog-title>Solicitar Usuario</h1>
<div mat-dialog-content>
    <form name="registerForm" [formGroup]="formGroup">
        <mat-form-field class="full-width">
            <mat-label>Relación</mat-label>
            <mat-select formControlName="relacion" (selectionChange)="onChangeRelacion($event.value)">
                <mat-option *ngFor="let relacion of relaciones" [value]="relacion.value">
                    {{relacion.viewValue}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="formGroup.get('relacion').hasError('required')">
                Por favor, seleccione relación
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" *ngIf="this.f.relacion.value != '' && !esPersonal && !esMedico">
            <input autofocus matInput placeholder="Nombre/ Razón Social" formControlName="nombre" />
            <mat-error *ngIf="formGroup.get('nombre').hasError('required')">
                Por favor ingrese el nombre/ razón social
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" *ngIf="this.f.relacion.value != '' && (esPersonal || esMedico)">
            <input matInput placeholder="Documento" formControlName="nroDoc" />
            <mat-error *ngIf="formGroup.get('nroDoc').hasError('required')">
                Por favor ingrese su documento
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
            <input matInput type="email" placeholder="Email" formControlName="email" />
            <mat-error *ngIf="formGroup.get('email').hasError('email') && !formGroup.get('email').hasError('required')">
                Por favor ingrese un email valido.
            </mat-error>
            <mat-error *ngIf="formGroup.get('email').hasError('required')">
                Email es <strong>requerido</strong>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" *ngIf="this.f.relacion.value != '' && !esPersonal && !esMedico">
            <input matInput placeholder="{{clienteLabel}}" formControlName="cliente" />
            <mat-error *ngIf="formGroup.get('cliente').hasError('required')">
                Por favor ingrese el cliente
            </mat-error>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="onCancelClick()">Cancelar</button>
    <button mat-raised-button color="accent" (click)="onSendClick()" [disabled]="!formGroup.valid">Enviar</button>
</div>