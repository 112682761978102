<mat-sidenav-container class="sidenav-container" fullscreen>
  <mat-sidenav #snav mode="over">
    <div class="logo-nav">
        <img src="./assets/images/paramedic-logo.png" />
    </div>
    <mat-divider></mat-divider>
    <mat-nav-list  *ngFor="let grupoAccesosMicrositiosItem of grupoAccesosMicrositios | async; let i = index">
      <h3 mat-subheader>{{grupoAccesosMicrositiosItem.nombre}}</h3>
      <mat-list-item *ngFor="let accesoMicrositio of grupoAccesosMicrositiosItem.accesosMicrositios" class="item-nav">
        <mat-icon mat-list-icon>folder</mat-icon>
        <a matLine (click) = "goToPage(accesoMicrositio.url, accesoMicrositio.codigo)">{{accesoMicrositio.titulo}}</a>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav #snavAlertas mode="over" position="end" class="alerta-sidenav">
    <app-alertas></app-alertas>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content" scrollDirection (scrolledUp)="flag = true" (scrolledDown)="flag = false">
    <app-secure-toolbar (menuOutput) = "snav.toggle()"
                        (messagesOutput) = "showMessages()"
                        (notificationsOutput) = "snavAlertas.toggle()"
                        (logoutOutput) = "logout()"
                        [title] = "titulo"
                        [userName] = "userName$ | async"
                        [cantidadMensajesPendientes] = "cantidadMensajesPendientes$ | async"
                        [cantidadMensajesPendientes]= "cantidadAlertas$ | async" [isVisible]="!flag">
    </app-secure-toolbar>
    <app-progress-bar [topbar]="56">
    </app-progress-bar>
    <div class="page-content" ngClass.xs="page-content-mobile">
      <router-outlet></router-outlet>
    </div>
    <footer class="footer" ngClass.xs="footer-mobile">
      <p>Acceso no autorizado, uso, reproducción, o divulgación está prohibido. PARAMEDIC no asumirá responsabilidad ni obligación legal alguna por cualquier información incorrecta o alterada contenida en el sistema</p>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
