<form [formGroup]="formGroup">
    <div fxLayout="row" fxLayout.xs="column" style="margin-top: 10px;" fxLayoutGap="20px">
        <div fxFlex="30%" fxFlex.xs="100%" >
            <mat-form-field class="ml-5 mr-5 medium-form-field-50-100" style="width: 100%;">
                <input  type="text" placeholder="Grupo" matInput formControlName="grupoName" [matAutocomplete]="autoGru" (blur)='outGrupo()'>
                <mat-autocomplete #autoGru="matAutocomplete" (optionSelected)='setGrupo($event)' >
                    <mat-option *ngFor="let item of filteredGrupos | async" [value]="item">
                        {{item.descripcion}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="formGroup.controls['grupo'].hasError('required')">
                    Ingrese un Grupo
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="30%" fxFlex.xs="100%">
            <mat-form-field class="ml-5 mr-5 medium-form-field-50-100" style="width: 100%;">
                <input type="text" placeholder="Sub Grupo" matInput formControlName="subGrupoName" [matAutocomplete]="autoSub" (blur)='outSubGrupo()'>
                <mat-autocomplete #autoSub="matAutocomplete" (optionSelected)='setSubGrupo($event)' >
                    <mat-option *ngFor="let item of filteredSubGrupo | async" [value]="item">
                        {{item.descripcion}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="formGroup.controls['subGrupo'].hasError('required')">
                    Ingrese un Grupo
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="30%" fxFlex.xs="100%">
            <mat-form-field class="ml-5 mr-5 medium-form-field-50-100" style="width: 100%;">
                <input  type="text" placeholder="Sub Grupo 1" matInput formControlName="subGrupoName1" [matAutocomplete]="autoGru1" (blur)='outSubGrupo1()'>
                <mat-autocomplete #autoGru1="matAutocomplete" (optionSelected)='setSubGrupo1($event)' >
                    <mat-option *ngFor="let item of filteredSubGrupo1 | async" [value]="item">
                        {{item.descripcion}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div fxFlex="10%" fxFlex.xs="100%">
            <mat-checkbox style="width: 10%; margin-left: 45%;" (change)="cambioMail()" [checked]="mail" color="primary">Mail</mat-checkbox> 
        </div>              
    </div>
       
</form>