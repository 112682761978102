<br />
<br />
<mat-card class="example-card">
    <img mat-card-image src="../../../../assets/images/404.gif" alt="ERROR">
    <mat-card-content>
        <p>
            No podemos mostrar la pagina solicitada, puede ingresar nuevamente o intentar ir a la pagina principal.
        </p>
    </mat-card-content>
    <mat-card-actions>
        <!-- <button mat-raised-button color="accent">Ingresar</button> -->
        <button mat-raised-button color="accent" color="primary" (click)=GoHome()>Pagina Principal</button>
    </mat-card-actions>
</mat-card>

