import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from '@app/modules/core/services/notification.service';
import { Destinatario } from '../../models/destinatario';

@Component({
  selector: 'app-lista-usuarios',
  templateUrl: './lista-usuarios.component.html',
  styleUrls: ['./lista-usuarios.component.css']
})
export class ListaUsuariosComponent {
  @Input() usuariosRecive: Destinatario[];
  @Output() usuariosEmitted = new EventEmitter<Destinatario[]>();
  usuariosAgregar: Destinatario[] = [];
  usuariosEnviar: Destinatario[] = [];
  selectedId: number;
  selectedBorrarId: number;

  constructor(private notService: NotificationService) { }

  ngOnChanges() {
    this.usuariosAgregar = [];
    this.usuariosRecive.forEach(element => {
      this.usuariosAgregar.push(element);
    });

  }

  agregar(){

    if(this.selectedId == null){
      this.notService.showAlert("Seleccione un Usuario para agregar")
    }else{
      let user = this.usuariosAgregar.find(i=> i.id == this.selectedId);
      let index = this.usuariosAgregar.findIndex(i=> i.id == this.selectedId);
      if(user != null && index != null){
        this.usuariosAgregar.splice(index, 1);
        this.usuariosEnviar.push(user);
        this.selectedId = null;
        this.usuariosEmitted.emit(this.usuariosEnviar)
      }
    }
  }

  agregarTodos(){
    this.usuariosEnviar = [];
    this.usuariosRecive.forEach(element => {
      this.usuariosEnviar.push(element);
    });
    this.usuariosAgregar = [];
    this.usuariosEmitted.emit(this.usuariosEnviar);
  }

  borrar(){
    if(this.selectedBorrarId == null){
      this.notService.showAlert("Seleccione un Usuario para borrar")
    }else{
      let index = this.usuariosEnviar.findIndex(i=> i.id == this.selectedBorrarId);
      let user = this.usuariosEnviar.find(i=> i.id == this.selectedBorrarId)

      if(user != null && index != null){
        this.usuariosAgregar.push(user);
        this.usuariosEnviar.splice(index, 1);
        this.selectedBorrarId = null;
        this.usuariosEmitted.emit(this.usuariosEnviar)
      }

    }

  }

  borrarTodos(){
    this.usuariosEnviar = [];
    this.usuariosAgregar = [];
    this.usuariosRecive.forEach(element => {
      this.usuariosAgregar.push(element);
    });
    this.usuariosEmitted.emit(this.usuariosEnviar)
  }

  select(id: number){
    this.selectedId = id;
  }

  selectBorrar(id: number){
    this.selectedBorrarId = id;

  }
}

