import { FormControl } from '@angular/forms';
import { Component, ViewChild, ViewChildren, QueryList, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryComponent } from '@kolkov/ngx-gallery';
import { CommonService } from '@app/modules/shared/services/common.service';
import { Observable } from 'rxjs';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { Router } from '@angular/router';
import { MensajeLight } from '../../models/mensaje-light';
import { MensajesService } from '../../services';

@Component({
  selector: 'app-mensaje-lista',
  templateUrl: './mensaje-lista.component.html',
  styleUrls: ['./mensaje-lista.component.css']
})

export class MensajeListaComponent implements OnInit  {
  // Filtros para busqueda
  anio: FormControl;
  descripcionInput: FormControl;
  mensajes$: Observable<MensajeLight[]>;

  // Datos para grilla
  isLoading: Boolean = false;
  dcMen: string[] = [
    'fecha',
    'contenido',
    'emisor',
    'notificados',
    'leidos',
    'editar'
  ];
  mtMen: MatTableDataSource<MensajeLight> = new MatTableDataSource();
  private paginator: MatPaginator;
  private sort: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  constructor(
    private service: MensajesService,
    private commonService: CommonService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.commonService.setTitulo('Listado de Mensajes');
  }

  ngOnInit() {
    this.descripcionInput = new FormControl();
    this.getMensajes();
  }

  setDataSourceAttributes() {
    this.mtMen.paginator = this.paginator;
    this.mtMen.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    if (filterValue) {
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    }
    this.mtMen.filter = filterValue;
  }

  getMensajes() {
    const that = this;
    that.isLoading = true;

    that.mensajes$ = that.service.GetMensajesLight();

    that.mensajes$.subscribe(
      mensajes => {
        console.log(mensajes);
        if (mensajes == null) {
          return;
        }
        that.isLoading = false;
        this.mtMen = new MatTableDataSource(mensajes);
        this.setDataSourceAttributes();
      },
      err => { this.isLoading = false; }
    );
  }

  edicion(id: number){
    this.router.navigate(["mensajeria/editar", id])
  }
}