import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-message',
  templateUrl: './generic-message.component.html',
  styles: [
  ]
})
export class GenericMessageComponent {

  constructor(public dialogRef: MatDialogRef<GenericMessageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }


  cerrar(): void{
    this.dialogRef.close();
  }
}
