<div class="toolbar">
  <div class="buttons">
    <button class="mat-button-icon-small" mat-icon-button type="button" mat-button (click) = "back_click()" matTooltip="Volver">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="tittle">
    Mensaje
  </div>
</div>
