<div class="box-disclaimer mat-h5">
  Los servicios aquí disponibles son sólo accesibles para personal autorizado. <br>Para ingresar usted deberá identificarse como tal con el correspondiente nombre de usuario y contraseña.
</div>
<div class="logo-form"></div>
<div class="login">
  <mat-card>
    <mat-card-title>
      <p class="mat-title" style="color:black;font-weight: bolder; text-align: center;">INGRESO</p>
    </mat-card-title>
    <mat-card-content>
        <form novalidate name="form" role="form" [formGroup]="loginForm" (ngSubmit)="!loginForm.invalid && login()">
            <mat-form-field class="full-width">
                <mat-icon matPrefix>person</mat-icon>
                <input autofocus matInput placeholder="Usuario" formControlName="username" required>
                <mat-error *ngIf="loginForm.get('username').hasError('required')">
                    Por favor ingrese su usuario de Windows
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-icon matPrefix>lock</mat-icon>
                <input matInput type="password" placeholder="Contraseña" formControlName="password" required/>
                <mat-error *ngIf="loginForm.get('password').hasError('required')">
                    Por favor ingrese su contraseña
                </mat-error>
            </mat-form-field>
            <a href="javascript:void(0)" (click)="forgot()" class="forgot-password">Olvidé mi contraseña</a>
            <button class="full-width login-button" [disabled]="loading" mat-raised-button color="primary" type="submit"> INGRESAR</button>
            <div class="link-login">
              <a href="javascript:void(0)" (click)="register()"> <span class="icon material-icons">person_add</span> <span class="label-title">Solicitar Usuario</span></a>
            </div>
            <div class="link-login">
              <a href="http://www.paramedic.com.ar/" target="_blank"> <span class="icon material-icons">phone</span> <span class="label-title">Teléfonos utiles</span></a>
            </div>
        </form>
        <div style="text-align:right;">
            <!-- <span>v {{version}}</span> -->
        </div>
    </mat-card-content>
  </mat-card>
</div>
